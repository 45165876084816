import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import Header from '../components/SucessoContent'
import LogoKK from '../images/LP/logo-fractal.svg'
import LogoKKK from '../images/LP/logo-kk.svg'  
import imgSucesso from '../images/LP/sucesso.png'
import imgSucessoM from '../images/LP/sucesso-m.svg'

import {
  Sucessocont,
  content,
  mobile,
  desktop,
  logo
} from '../components/styles/Header.styles'

import { 
  container,
  columns,
  column,
  footerLp
} from '../components/styles/Base.styles'

const LpPage = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Imagine uma escola, Imagine Kingdom" />
    <section css={logo}>
      <div css={container}>
        <div css={columns}>
          <div css={column}>
            <a href='/index'><img src={LogoKKK} alt='' style={{ width: 160 }} /></a>
          </div>
        </div>
      </div>
    </section>
    <div css={[Sucessocont, content]}>
      <div css={container}>
        <div css={columns}>
          <div css={column} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{ marginBottom: 60, textAlign: 'center' }}><iframe src="https://forms.monday.com/forms/embed/4a67836ad5b3234fd4661e59b13ad9aa?r=use1" width="750" height="1300" /></div>
          </div>
        </div>
      </div>
    </div>
    <section css={footerLp}>
      <div css={container}>
        <div css={columns}>
          <div css={column}>
            <img src={LogoKK} alt='' />
            <p>@2024 FractalTecnologia. Alguns direitos reservados.</p>
          </div>
        </div>
      </div>
    </section>
  </Fragment>
)

export default LpPage
